const MockAdapter = require('axios-mock-adapter');
const axios = require('axios');
const MockAxios = new MockAdapter(axios);
export default MockAxios;

// // mockAxios.js
// const MockAdapter = require('axios-mock-adapter');
// const axios = require('axios');
//
// const mockAxios = axios.create();
// const mock = new MockAdapter(mockAxios);
