import MockAxios from "../mock";

const database = {
  information: {
    name: 'Soroosh',
    aboutContent: "Welcome to my corner of the digital realm! I'm a passionate DevOps engineer and instructor, weaving together the intricate threads of technology and teaching. Join me on a journey of continuous learning, where code meets collaboration, and innovation thrives. Explore my insights, tutorials, and experiences as we navigate the exciting world of DevOps together.",
    age: 32,
    phone: '',
    nationality: 'Iranian',
    language: 'Persian, English, Swedish',
    email: 'lxsoroosh@gmail.com',
    address: 'Gothenburg, Sweden',
    // freelanceStatus: 'Not Available',
    socialLinks: {
      instagram: 'https://instagram.com/lxsoroosh?igshid=OGQ5ZDc2ODk2ZA==',
      twitter: 'https://twitter.com/Lxsoroosh',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/lxsoroosh/',
      dribbble: '',
      // github: 'https://gitlab.com/lxsoroosh'
    },
    brandImage: '/images/soroosh.jpg',
    aboutImage: '/images/about-image.jpeg',
    aboutImageLg: '/images/about_soroosh.jpg',
    cvfile: '/files/resume.pdf'
  },
  services: [
    {
      title: "Consultation and Training",
      icon: 'consulting',
      details: " Providing expertise and knowledge transfer to internal teams on DevOps best practices, tool selection, and workflow enhancements."
    },
    {
      title: "Continuous Integration and Continuous Deployment (CI/CD) Setup and Management",
      icon: 'code',
      details: "Building, testing, and deploying software projects seamlessly"
    },
    {
      title: "Infrastructure as Code (IaC) Development",
      icon: 'cloud-network',
      details: "Using tools like Terraform, CloudFormation, or Pulumi to automate the setup, configuration, and teardown of infrastructure resources."
    },
    {
      title: "Configuration Management",
      icon: 'layout',
      details: "Using tools such as Ansible, Puppet, Chef, or SaltStack to ensure consistent and repeatable application and server configurations."
    },
    {
      title: "Cloud Services Setup and Management",
      icon: 'cloud',
      details: "Using tools such as Ansible, Puppet, Chef, or SaltStack to ensure consistent and repeatable application and server configurations."
    },
    {
      title: "Monitoring, Logging, and Alerting",
      icon: 'display',
      details: "Using tools such as Ansible, Puppet, Chef, or SaltStack to ensure consistent and repeatable application and server configurations."
    },
    {
      title: "Performance Optimization",
      icon: 'stats-up',
      details: " Analyzing systems to identify bottlenecks and optimizing applications or infrastructure to achieve better performance and reduced costs."
    },
    {
      title: "Security and Compliance",
      icon: 'protection',
      details: "Setting up and testing backup and recovery solutions, as well as designing systems that remain operational even when individual components fail."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Soroosh is a dedicated person pursuing excellence, in everything he does. He is looking to improve his skills every chance he gets, be it programming or training at the gym!",
      author: {
        name: 'Shwan Ciyako',
        designation: 'Senior Azure Cloud Architect, Nordcloud'
      }
    },
    {
      id: 3,
      content: "Reliable, dependable, capable, loyal, trustworthy, good communicator.",
      author: {
        name: 'Martin Ndirangu',
        designation: 'Cyber Security Engineer, Haleytek'
      },
    },
    {
      id: 2,
      content: "Soroosh puts his full effort into everything he does, whether it's coding, hitting the gym, learning something new, or being a great friend. Those who know him can't help but find inspiration in his dedication and appreciate his friendship.",
      author: {
        name: 'Hajar Pasha',
        designation: 'Scrum Master, Volvo Cars'
      }
    },
    {
      id: 3,
      content: "Throughout our college years, I've observed Soroosh's exceptional growth as a DevOps Engineer, particularly his expertise in Kubernetes, Azure, and web development.",
      author: {
        name: 'Omid Rajabagha',
        designation: 'Cloud Infrastructure Engineer, Zenseact'
      },
    },
  ],
  skills: [
    {
      title: "Programming and Scripting Languages:" +
          " Python/JavaScript/TypeScript/BashScript/HTML&CSS",
      value: 85
    },
    {
      title: "Web Development and Frameworks:" +
          " NodeJS/ReactJS/ExpressJS/Redux/Django",
      value: 80
    },
    {
      title: "Databases:" + "\n" +
          " MongoDB/MySQL/PostgreSQL",
      value: 65
    },
    {
      title: "Containerization and Orchestration:\n" +
          "\n" +
          "Docker/Kubernetes/DockerSwarm",
      value: 80
    },
    {
      title: "Cloud Providers:\n" +
          "AWS/Azure/DigitalOcean",
      value: 75
    },
    {
      title: "Version Control:\n" +
          "\n" +
          "Git",
      value: 85
    },
    {
      title: "CI/CD and Automation:\n" +
          "\n" +
          "Airﬂow/Gitlab/Jenkins/Terraform/Ansible",
      value: 80
    },
    {
      title: "Virtualization and Environment Management:\n" +
          "\n" +
          "Vagrant/Xen",
      value: 40
    },
    {
      title: "Monitoring and Logging:\n" +
          "Zabbix/Prometheus/Grafana/Graylog/Fluentd/ELK",
      value: 70
    },
  ],
  projects: [
    {
      id: 1,
      title: "RS3",
      subtitle: "How to become a cool TECH ENGINEER",
      imageUrl: "/images/rs3.png",
      largeImageUrl: ["/images/rs3.png"],
      url: 'https://lxsoroosh.com/blogs/2/RS3-the-Ultimate-Tech-Learning-Experience'
    },
    {
      id: 1,
      title: "Dmension",
      subtitle: "Dreams Beyond Boundaries",
      imageUrl: "/images/dmension.png",
      largeImageUrl: ["/images/dmension.png"],
      url: 'https://lxsoroosh.com/blogs/4/Dmension'
    },
    {
      id: 3,
      title: "NeoFlare",
      subtitle: "Young Talent Program",
      imageUrl: "/images/neoflare.png",
      largeImageUrl: ["/images/neoflare.png"],
      url: 'https://lxsoroosh.com/blogs/3/NeoFlare-Discovering-certifing-tech-talents'
    },
    // {
    //   id: 4,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: '#'
    // },
    // {
    //   id: 5,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // },
    // {
    //   id: 6,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/portfolio-image-6.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    // },
    // {
    //   id: 7,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: 'https://twitter.com'
    // },
    // {
    //   id: 8,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    // },
    // {
    //   id: 9,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    // },
    // {
    //   id: 10,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: '#'
    // },
    // {
    //   id: 11,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg"
    //   ],
    //   url: 'https://facebook.com'
    // },
    // {
    //   id: 12,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: 'https://pinterest.com'
    // },
    // {
    //   id: 13,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: 'https://dribbble.com'
    // },
    // {
    //   id: 14,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // },
    // {
    //   id: 15,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/portfolio-image-6.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    // },
    // {
    //   id: 16,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: 'https://twitter.com'
    // },
    // {
    //   id: 17,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    // },
    // {
    //   id: 18,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    // },
    // {
    //   id: 19,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: 'https://dribbble.com'
    // },
    // {
    //   id: 20,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg"
    //   ],
    //   url: 'https://facebook.com'
    // },
    // {
    //   id: 21,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: 'https://pinterest.com'
    // },
    // {
    //   id: 22,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: '#'
    // },
    // {
    //   id: 23,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2022 - Present",
        position: "CI Developer",
        company: "Haleytek",
        details: "I adeptly developed web applications using Django and React, integrating Python with key web tools. I set up robust CI/CD pipelines using Airflow and Gerrit, and spearheaded the creation of container-native MicroServices. Additionally, I managed Azure cloud services while leveraging Terraform, Ansible, and Docker for streamlined automation and provisioning."
      },
      {
        id: 2,
        year: "2022 - 2023",
        position: "Devops Engineer",
        company: "Amaris",
        details: "As part of the DevOps Platform team, I was pivotal in front-end and back-end development while also overseeing infrastructure maintenance. I seamlessly integrated automated builds with deployment pipelines spanning Integration, QA, and Production stages. I championed cloud automation, orchestrated Docker clusters via Kubernetes, and crafted playbooks for diverse services. Furthermore, I installed and managed monitoring tools like Zabbix and Prometheus, integrating them into a unified provisioning and monitoring platform"
      },
      {
        id: 3,
        year: "2019 - 2021",
        position: "DevOps Engineer",
        company: "Bluebulls LLC",
        details: "I established and upheld automated CI/CD pipelines using Gitlab, while provisioning servers and deploying features via Ansible. I crafted Docker images tailored for diverse environments and orchestrated them using Kubernetes. Leveraging shell scripts, I automated daily operations and tasks. My expertise also covered the configuration and management of monitoring tools like Zabbix and Prometheus. Additionally, I innovated processes for release management, code deployment, and system monitoring"
      },
      {
        id: 4,
        year: "2017 - 1018",
        position: "Junior Web Developer",
        company: "Podsms co.",
        details: "I spearheaded the design and development of a user-centric website utilizing Javascript and ReactJS. Drawing on my expertise in NodeJS, I coded and refined applications, and crafted intuitive user interfaces with ReactJS, HTML, and CSS. I integrated RESTful Web services to populate front-end content and produced web design mockups using Gimp. Throughout the process, I employed GIT for version control and project management, leveraging Ajax for data requests and processing."
      },
      {
        id: 5,
        year: "2016 - 1017",
        position: "Intern Web Developer",
        company: "Yellowen",
        details: " I embraced hands-on learning and agile methodologies to construct full MERN stack applications. I crafted and refined code in ReactJS, JavaScript, MongoDB, and NodeJS, playing a pivotal role in introducing diverse web platform features. My design contributions included creating mockups and prototypes to enhance user experience and site interactions. I actively collaborated with product management on system design, development, and testing, utilizing a backend powered by Node.js/Express.js, integrated with MongoDB and a RESTful API. Additionally, I implemented user authentication mechanisms using PassportJs."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2015 - 2018",
        graduation: "Master of Science",
        university: "Karaj Azad University",
        details: "Industrial Engineering"
      },
      {
        id: 2,
        year: "2010 - 2014",
        graduation: "Bachelor of Science",
        university: "Karaj Azad University",
        details: "Industrial Engineering"
      },
    ],
    certification: [
      {
        id: 1,
        year: "2021",
        certName: "DevOps Tools Engineer",
        issuingAuthority: "issuing Authority: Linux Professional Institute",
        linkName: "lpi.org/v/LPI000400722/bvv6e4d873",
        linkRef: "https://cs.lpi.org/caf/Xamman/certification/verify/LPI000400722/v3jnvq8645",
        details: 'Canada'
      },
      {
        id: 2,
        year: "2018",
        certName: "Linux Engineer (LPIC-2)",
        issuingAuthority: "issuing Authority: Linux Professional Institute",
        linkName: "lpi.org/v/LPI000400722/y34nr6d2r6d",
        linkRef: "https://cs.lpi.org/caf/Xamman/certification/verify/LPI000400722/v3jnvq8645",
        details: 'Canada'
      },
      {
        id: 3,
        year: "2018",
        certName: "Linux Administrator (LPIC-1)",
        issuingAuthority: "issuing Authority: Linux Professional Institute",
        linkName: "lpi.org/v/LPI000400722/v3jnvq8645",
        linkRef: "https://cs.lpi.org/caf/Xamman/certification/verify/LPI000400722/v3jnvq8645",
        details: 'Canada'
      },

    ]
  },

  blogs: [
    {
      id: 1,
      title: 'Why Now’s the Time to Dive into the World of Tech.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/importance-of-IT.md',
      createDay: "23",
      createMonth: 'August',
      createYear: "2023"
    },
    {
      id: 2,
      title: 'RS3: The Ultimate Tech Learning Experience',
      featuredImage: '/images/rs3.png',
      filesource: '../../blog/RS3-the-Ultimate-Tech-Learning-Experience.md',
      createDay: "1",
      createMonth: 'September',
      createYear: "2023"
    },
    {
      id: 3,
      title: 'NeoFlare: Discovering, Certifying, and Showcasing Tomorrow\'s Tech Talents',
      featuredImage: '/images/neoflare.png',
      filesource: '../../blog/NeoFlare-Discovering-certifing-tech-talents.md',
      createDay: "4",
      createMonth: 'September',
      createYear: "2023"
    },
    {
      id: 4,
      title: 'Dmension: Dreams Beyond Boundaries',
      featuredImage: '/images/dmension.png',
      filesource: '../../blog/Dmension.md',
      createDay: "7",
      createMonth: 'September',
      createYear: "2023"
    },
  ],
  contactInfo: {
    phoneNumbers: ['+46790120117', '+989125648832'],
    emailAddress: ['admin@lxsoroosh.com', 'lxsoroosh@gmail.com'],
    address: "Gothenburg, Sweden"
  }
}


MockAxios.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

MockAxios.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

MockAxios.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

MockAxios.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

MockAxios.onGet("/api/projects").reply(config => {
  const response = database.projects;
  return [200, response];
});

MockAxios.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

MockAxios.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

MockAxios.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});