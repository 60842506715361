import React from "react";

function Resume(props) {
  const {year, position, certName, issuingAuthority, graduation, university, company, details, linkName, linkRef} = props.resumeData;
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position || graduation || certName}</h5>
        <h6 className="mi-resume-company">{company || university || issuingAuthority}</h6>
        <p>{details}</p>
        <a href={linkRef} target="_blank" rel="noopener noreferrer">{linkName}</a>
      </div>
    </div>
  );
};

export default Resume;
